import { Trans } from "@lingui/macro";
import { Modal } from "@mantine/core";

type Props = {
	text: string;
	open: boolean;
	close: () => void;
};

export default function HelpDeskModal({ text, open, close }: Props) {
	return (
		<Modal
			opened={open}
			onClose={close}
			size={"lg"}
			centered
			styles={{
				header: {
					color: "white",
					background: "#093c71",
					padding: "1rem",
					position: "relative",
				},
				close: { color: "white", background: "#093c71" },
				body: { padding: "2rem", fontSize: "1.25rem" },
			}}
			title={
				<span className="text-lg absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
					<Trans>Bantuan</Trans>
				</span>
			}
		>
			<div
				style={{
					textAlign: "center",
					lineHeight: "1.5",
				}}
				// biome-ignore lint/security/noDangerouslySetInnerHtml: need for rendering help text
				dangerouslySetInnerHTML={{
					__html: (text || "").replace(/\n/g, "<br />"),
				}}
			/>
		</Modal>
	);
}
